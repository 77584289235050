<template>
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_522_78)">
      <path
        d="M4.44444 0H2.22222C1.63285 0 1.06762 0.195105 0.650874 0.542395C0.234126 0.889683 0 1.36071 0 1.85185V9.25925H2.22222V5.55556H4.44444V9.25925H6.66667V1.85185C6.66667 1.36071 6.43254 0.889683 6.01579 0.542395C5.59905 0.195105 5.03381 0 4.44444 0ZM4.44444 3.7037H2.22222V1.85185H4.44444M12.2222 0H7.77778V9.25925H10V5.55556H12.2222C12.8116 5.55556 13.3768 5.36045 13.7936 5.01316C14.2103 4.66587 14.4444 4.19484 14.4444 3.7037V1.85185C14.4444 1.36071 14.2103 0.889683 13.7936 0.542395C13.3768 0.195105 12.8116 0 12.2222 0ZM12.2222 3.7037H10V1.85185H12.2222M18.8889 1.85185V7.40741H20V9.25925H15.5556V7.40741H16.6667V1.85185H15.5556V0H20V1.85185H18.8889Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_522_78">
        <rect
          width="20"
          height="10"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
