<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { usePreferencesStore } from '@/modules/preferences/preferencesStore'
import getMenu from './menu.js'
import HeaderMenuMobile from './HeaderMenuMobile.vue'
import HeaderMenuLeft from './HeaderMenuLeft.vue'
import HeaderMenuMobileItens from './HeaderMenuMobileItens.vue'

const preferencesStore = usePreferencesStore()
const signOut = () => preferencesStore.signOut()

const menu = ref({ list: [], rightList: [], pathActive: true })
const sessionStore = ref({})
const mobileMenuState = ref(false)
const curtainState = ref(false)
const windowHeight = ref(window.innerHeight) // Reactive variable for window height
const startX = ref(0)
const currentX = ref(0)
const dragging = ref(false)

const methods = {
  onChangeRoute: () => {
    preferencesStore.getPreferences().then((session) => {
      sessionStore.value = session
      menu.value.list = getMenu(session)
    })
  },

  toogleMobileMenu: (state) => {
    mobileMenuState.value = state
    if (state) curtainState.value = state

    setTimeout(() => {
      curtainState.value = state
    }, 300)
  },

  handleTouchStart: (e) => {
    startX.value = e.clientX
    dragging.value = true
  },

  handleTouchMove: (e) => {
    if (!dragging.value) return
    currentX.value = e.clientX
  },

  handleTouchEnd: () => {
    if (startX.value > 200 || (currentX.value && startX.value - currentX.value > 50)) methods.toogleMobileMenu(false)
    dragging.value = false
    currentX.value = 0
  },

  handleMouseDown: (e) => {
    startX.value = e.clientX
    dragging.value = true
  },

  handleMouseMove: (e) => {
    if (!dragging.value) return
    currentX.value = e.clientX
  },

  handleMouseUp: () => {
    if (startX.value > 200 || (currentX.value && startX.value - currentX.value > 50)) methods.toogleMobileMenu(false)
    dragging.value = false
    currentX.value = 0
  },

  updateWindowSize: () => {
    if (window.innerWidth > 860) methods.toogleMobileMenu(false)

    if (window.innerHeight !== windowHeight.value) {
      windowHeight.value = window.innerHeight
    }
  }

}

// watch(windowHeight, (newHeight) => {
//   const menuItems = document.querySelector('.menu-itens')
//   if (menuItems) {
//     menuItems.style.height = `${newHeight}px`
//   }
// })

onMounted(() => {
  methods.onChangeRoute()
  window.addEventListener('popstate', methods.onChangeRoute)
  window.addEventListener('resize', methods.updateWindowSize)

  // const menuItems = document.querySelector('.menu-itens')
  // if (menuItems) {
  //   menuItems.style.height = `${windowHeight.value}px`
  // }
})

onBeforeUnmount(() => {
  window.removeEventListener('popstate', methods.onChangeRoute)
  window.removeEventListener('resize', methods.updateWindowSize)
})
</script>

<template>
  <nav class="navbar">
    <div
      v-show="curtainState"
      class="menu-collapse-container"
      @touchstart="methods.handleTouchStart"
      @touchmove="methods.handleTouchMove"
      @touchend="methods.handleTouchEnd"
      @mousedown="methods.handleMouseDown"
      @mousemove="methods.handleMouseMove"
      @mouseup="methods.handleMouseUp"
    >
      <transition name="slide-fade">
        <div
          class="menu-itens"
          v-show="mobileMenuState"
        >
          <HeaderMenuMobileItens
            :menu-config="menu"
            :sign-out="signOut"
            :session-store="sessionStore"
            @mobile-menu-trigger="methods.toogleMobileMenu"
          />
        </div>
      </transition>
    </div>

    <div class="menu-laptop">
      <HeaderMenuLeft
        :menu-config="menu"
        :sign-out="signOut"
        :session-store="sessionStore"
      />
    </div>

    <div class="menu-mobile">
      <HeaderMenuMobile
        :menu-config="menu"
        :sign-out="signOut"
        :curtain-state="curtainState"
        @mobile-menu-trigger="methods.toogleMobileMenu"
      />
    </div>
  </nav>
</template>

<style lang="sass" scoped>
.navbar
  background-color: #3d55ae
  min-width: 800px

.menu-laptop
  display: flex

.menu-mobile
  display: none

.menu-collapse-container
  display: none

@media (max-width: 980px)
  .menu-laptop
    display: none

  .menu-mobile
    display: flex
  .menu-collapse-container
    position: fixed
    top: 40px
    width: 100%
    height: 100%
    display: flex
    flex-direction: row
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 10000

  .menu-itens
    width: 200px
    height: 100%
    background-color: #FFFFFF
    color: #6f6f6f
    font-family: Work-sans-semi-bold
    font-size: 13px

.slide-fade-enter-active, .slide-fade-leave-active
  transition: transform 0.2s ease-out

.slide-fade-enter-from, .slide-fade-leave-to
  transform: translateX(-100%)

.slide-fade-enter-to, .slide-fade-leave-from
  transform: translateX(0)

</style>
