<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_522_76)">
      <path
        d="M14 10.2941C14.2184 10.2941 14.4279 10.3809 14.5823 10.5353C14.7367 10.6897 14.8235 10.8992 14.8235 11.1176V14C14.8235 14.2184 14.7367 14.4279 14.5823 14.5823C14.4279 14.7367 14.2184 14.8235 14 14.8235C10.287 14.8235 6.72601 13.3485 4.1005 10.723C1.475 8.09752 0 4.53656 0 0.823529C0 0.605116 0.0867646 0.395648 0.241206 0.241206C0.395648 0.0867646 0.605116 0 0.823529 0H3.70588C3.9243 0 4.13376 0.0867646 4.28821 0.241206C4.44264 0.395648 4.52941 0.605116 4.52941 0.823529C4.52941 1.84471 4.69412 2.84118 4.99882 3.76353C5.08941 4.05176 5.02353 4.37294 4.79294 4.59529L2.98118 6.41529C4.16706 8.74588 6.07765 10.6565 8.40824 11.8424L10.22 10.0306C10.4506 9.8 10.7718 9.73412 11.06 9.82471C11.9824 10.1294 12.9706 10.2941 14 10.2941ZM7.41176 0H9.05882V1.64706H7.41176M9.88235 0H14.8235V1.64706H9.88235M7.41176 2.47059H9.05882V4.11765H7.41176M9.88235 2.47059H14.8235V4.11765H9.88235M7.41176 4.94118H9.05882V6.58824H7.41176M9.88235 4.94118H14.8235V6.58824H9.88235"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_522_76">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
