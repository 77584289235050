<script setup>
const props = defineProps({
  color: {
    type: String,
    default: 'white'
  }
})
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="8 0 24 24"
    fill="white"
  >
    <path
      d="M15 6L9 12L15 18"
      :stroke="props.color"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
