<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 3.31579C3.5 5.1439 5.07033 6.63159 7 6.63159C8.92967 6.63159 10.5 5.1439 10.5 3.31579C10.5 1.48768 8.92967 0 7 0C5.07033 0 3.5 1.48768 3.5 3.31579ZM13.2223 14H14V13.2632C14 10.4197 11.557 8.10528 8.55556 8.10528H5.44444C2.44223 8.10528 0 10.4197 0 13.2632V14H13.2223Z"
      fill="white"
    />
  </svg>
</template>
