import { h, createApp } from 'vue'
import singleSpaVue from 'single-spa-vue'
import HeaderMenu from '@/modules/menu/components/HeaderMenu.vue'
import { createPinia } from 'pinia'
import '@/styles/app.scss'

const pinia = createPinia()
const vueLifecycles = singleSpaVue({
  createApp,

  appOptions: {
    render () {
      return h(HeaderMenu)
    }
  },
  handleInstance (app) {
    app.use(pinia)
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
