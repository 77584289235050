<script setup>
const props = defineProps({
  color: {
    type: String,
    default: 'white'
  }
})
</script>
<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_521_72)">
      <path
        d="M7.00147 9.45007C5.61264 9.45007 4.48284 8.35107 4.48284 7.00007C4.48284 5.64904 5.61264 4.55004 7.00147 4.55004C8.39027 4.55004 9.52007 5.64904 9.52007 7.00007C9.52007 8.35107 8.39027 9.45007 7.00147 9.45007ZM12.3488 7.67907C12.3768 7.45507 12.3992 7.23107 12.3992 7.00007C12.3992 6.76904 12.3775 6.53804 12.3488 6.30004L13.8671 5.15904C13.9332 5.10744 13.9784 5.0336 13.9942 4.95121C14.0101 4.86882 13.9955 4.78348 13.9532 4.71104L12.514 2.28904C12.4711 2.21537 12.4033 2.15939 12.3229 2.13117C12.2424 2.10295 12.1546 2.10432 12.0751 2.13504L10.2831 2.83504C9.90927 2.56204 9.52007 2.32404 9.06717 2.14904L8.80117 0.294043C8.78535 0.210421 8.74048 0.135072 8.67454 0.081284C8.6086 0.0274959 8.52572 -0.00127559 8.44067 4.34173e-05H5.56224C5.47726 -0.00110946 5.3946 0.0277378 5.32879 0.0815115C5.26299 0.135285 5.21825 0.210541 5.20244 0.294043L4.93644 2.14904C4.48284 2.32404 4.09434 2.56204 3.71984 2.83504L1.92784 2.13504C1.84832 2.10432 1.76046 2.10295 1.68001 2.13117C1.59958 2.15939 1.53183 2.21537 1.48894 2.28904L0.0497413 4.71104C0.00580455 4.78311 -0.00964929 4.86898 0.00640563 4.95185C0.0224606 5.03471 0.068868 5.1086 0.136541 5.15904L1.65484 6.30004C1.62399 6.53215 1.60715 6.76591 1.60444 7.00007C1.60444 7.23107 1.62544 7.45507 1.65484 7.67907L0.135841 8.84107C0.0682822 8.89161 0.022014 8.96553 0.00608973 9.04841C-0.00983451 9.13122 0.00573125 9.21704 0.0497413 9.28907L1.48894 11.7111C1.57574 11.8651 1.76964 11.9211 1.92854 11.8651L3.72054 11.1581C4.09434 11.4381 4.48354 11.6761 4.93644 11.8511L5.20244 13.7061C5.23114 13.8741 5.38234 14.0001 5.56224 14.0001H8.44067C8.62057 14.0001 8.77177 13.8741 8.80047 13.7061L9.06647 11.8511C9.50096 11.6752 9.91011 11.4421 10.2831 11.1581L12.0751 11.8651C12.2333 11.9211 12.4279 11.8651 12.514 11.7111L13.9532 9.28907C13.9955 9.21662 14.0101 9.13129 13.9942 9.0489C13.9784 8.96651 13.9332 8.89266 13.8671 8.84107L12.3488 7.67907Z"
        :fill="props.color"
      />
    </g>
    <defs>
      <clipPath id="clip0_521_72">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
