<script setup>
import { onMounted, onBeforeUnmount, ref, markRaw, watch } from 'vue'
import VonixMenu from '@/assets/icons/VonixMenu.vue'
import { menuHelpers } from './menu.js'
import UserLogo from '@/assets/icons/UserLogo.vue'
import MenuIcon from '@/assets/icons/MenuIcon.vue'
import CloseIcon from '@/assets/icons/CloseIcon.vue'

const userLogo = markRaw(UserLogo)
const vonixLogo = markRaw(VonixMenu)
const menuIcon = markRaw(MenuIcon)

const menu = ref({ list: [], pathActive: true })
const navItem = ref(null)
const dropdown = ref(null)
const menuToogle = ref(false)

const props = defineProps(['menuConfig', 'signOut', 'curtainState'])
const emit = defineEmits(['mobile-menu-trigger'])

onMounted(() => {
  document.addEventListener('click', (e) => { menuHelpers.outClick(menu, e) })
  menuToogle.value = false
})
onBeforeUnmount(() => { document.removeEventListener('click', (e) => menuHelpers.outClick(menu, e)) })

const goToDashboardAgents = (e) => menuHelpers.goToDashboardAgents(e, menu)
const onClickMenu = (e) => {
  menuHelpers.onClickItem(e, menu)
  methods.onClickUser()
}

const methods = {
  menuToogle: () => {
    menuHelpers.closeAllDropdowns(menu)
    menuToogle.value = !menuToogle.value
    emit('mobile-menu-trigger', menuToogle.value)
  },

  onClickUser: () => {
    if (dropdown.value) dropdown.value.style.width = `${navItem.value.offsetWidth}px`
  }
}

watch(() => props.curtainState, (newState) => { menuToogle.value = newState })
watch(props.menuConfig, (newMenu) => { menu.value = newMenu })
</script>

<template>
  <ul class="nav-list">
    <template class="left-container">
      <div
        class="left-container"
        :class="[menuToogle ? 'vonix-logo active': '']"
      >
        <vonix-logo
          class="vonix-logo"
          :class="[menuToogle ? 'vonix-logo active': '']"
          @click="goToDashboardAgents"
        />
        <button
          class="menu-button"
          :class="[menuToogle ? 'menu-button active': 'menu-button']"
          @click="methods.menuToogle"
        >
          <menuIcon
            v-if="!menuToogle"
            class="icon"
          />
          <closeIcon
            v-if="menuToogle"
            color="#FFFFFF"
            width="16px"
            height="16px"
            opacity="1"
            style="margin-right: 5px; margin-left: 3px"
          />
          <span>Menu</span>
        </button>
      </div>
    </template>

    <template
      v-for="(item, index) in menu.list"
      :key="index"
    >
      <li
        v-if="item.separator"
        class="nav-item fill"
      />

      <li
        v-if="item.align === 'right'"
        class="nav-item item-margin"
      >
        <a
          v-if="item.link"
          :href="item.link"
          @click="onClickSubItem"
          :class="`link-menu ${menu.pathActive && item.active ? 'force-active' : ''}`.trim()"
        >
          <component
            v-if="item.icon"
            :is="item.icon"
            style="margin-left: 10px; margin-right: 10px"
          />
          <span
            v-if="item.label"
            style="margin: 0 10px 0 5px"
          >{{ item.label }}</span>
        </a>
      </li>
    </template>

    <li
      class="nav-item nav-user"
      ref="navItem"
      @click="methods.onClickUser"
      v-if="menu.list.length"
    >
      <a
        href="#"
        class="menu-user"
        @click="onClickMenu"
      >
        <user-logo />
      </a>

      <div
        class="dropdown"
        ref="dropdown"
      >
        <a
          href="#"
          class="user-submenu-mobile"
          @click="props.signOut"
        >
          Sair
        </a>
      </div>
    </li>
  </ul>
</template>

<style lang="sass" scoped>
  .icon
    height: 24px
    width: 24px

  .menu-button
    align-items: center
    background-color: #3d55ae
    border: none
    color: white
    cursor: pointer
    display: flex
    font-family: Work-sans-bold
    height:  100%
    justify-content: space-around
    width: 96px

  .navbar
    background-color: #3d55ae
    color: white
    display: flex
    padding: 0
    width: 100%

    .nav-list
      align-items: center
      display: flex
      list-style-type: none
      margin: 0
      padding: 0
      width: 100%

    .nav-item
      &.fill
        width: fill-available
        width: -webkit-fill-available

      a
        &.menu, &.link-menu
          align-items: center
          color: white
          display: flex
          font-family: Work-sans-bold
          font-size: 13px
          height: 40px
          justify-content: center
          text-decoration: none
          width: max-content
        &.menu-user
          align-items: center
          color: white
          display: flex
          font-family: Work-sans-bold
          font-size: 13px
          height: 40px
          justify-content: center
          text-decoration: none
          width: 60px

        &.menu.active
          background-color: #fd9802
          border-bottom-right-radius: 110px
          border-top-right-radius: 110px
          color: #fff
          transition: background-color 0.8s, color 0.8s

        &.menu.force-active
          background-color: #fd9802
          border-bottom-right-radius: 110px
          border-top-right-radius: 110px
          color: #fff
          transition: background-color 0.5s, color 0.5s

        &.link-menu.active,&.menu-user.active,&.link-menu.force-active
          background-color: #fd9802
          color: #fff
          transition: background-color 0.5s, color 0.5s

    .menu-trick
      background-color: #3d55ae
      border-bottom-right-radius: 110px
      border-top-right-radius: 110px
      display: block
      height: 40px
      margin-left: -1px
      transition: opacity 0.8s ease-out 0.8s, background-color 0.8s, color 0.8s
      width: 20px

    .nav-item .dropdown
      background-color: #ffffff
      border-radius: 0 0 7px 7px
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2)
      display: block
      max-height: 0
      overflow-y: auto
      position: absolute
      width: max-content
      z-index: 10000

      .nav-title
        color: #9f9f9f
        font-size: 10px
        font-weight: 500
        padding: 4px 20px

      .nav-separator
        border-top: 1px solid #d9d9d9
        display: block
        margin: 8px 20px
        width: auto

      a.submenu
        border-bottom-left-radius: 10px
        border-bottom-right-radius: 110px
        border-top-left-radius: 10px
        border-top-right-radius: 110px
        color: #6f6f6f
        display: block
        display: block
        font-family: Work-sans
        font-size: 13px
        font-weight: 400
        margin-left: 10px
        margin-right: 10px
        padding: 2px 10px
        text-decoration: none
        transition: opacity 0.8s ease-out 0.8s, background-color 0.8s, color 0.8s

        &:hover
          background-color: #3d55ae
          color: #fff
          transition: opacity 0.8s ease-out 0.8s, background-color 0.8s, color 0.8s

        &.active
          background-color: #3d55ae
          color: #fff
          transition: opacity 0.8s ease-out 0.8s, background-color 0.8s, color 0.8s

      a.user-submenu-mobile
        color: #6f6f6f
        display: flex
        font-family: Work-sans
        font-size: 13px
        font-weight: 400
        height: 40px
        margin: 0
        padding: 5px
        align-items: center
        justify-content: center
        text-decoration: none
        transition: opacity 0.3s ease-out 0.3s, background-color 0.3s, color 0.3

        &:hover
          background-color: #3d55ae
          color: #fff
          transition: opacity 0.8s ease-out 0.8s, background-color 0.8s, color 0.8s

    .item-margin:not(:first-child)
      margin-left: 10px

    .nav-item .dropdown
      &::-webkit-scrollbar
        width: 5px

      &::-webkit-scrollbar-track
        background: #f1f1f1
        border-radius: 5px

      &::-webkit-scrollbar-thumb
        background: #809bff
        border-radius: 5px

        &:hover
          background: #678bfc
          border-radius: 5px

    .nav-user
      margin-left: 10px
      margin-right: 15px

    .menu-name
      display: none

  .left-container
    align-items: center
    display: flex
    flex-direction: row
    min-width: 160px

  .vonix-logo
    width: 40px
    height: 40px

</style>
