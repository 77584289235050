<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H20C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40H0V0Z"
      fill="white"
    />
    <path
      d="M25.8998 6.09797C25.4229 6.22848 24.8707 6.50909 20.8422 8.64954C11.7498 13.4851 6.8742 16.1085 6.49142 16.376C5.80119 16.8589 5.31174 17.5964 5.09211 18.4904C4.96034 19.0255 4.97289 20.0305 5.11094 20.4546C5.37448 21.2378 6.0459 22.0991 6.69849 22.4841C7.33227 22.8626 25.3413 30.8632 25.7681 30.9611C26.3892 31.0982 26.9289 31.0785 27.5627 30.8828C28.2279 30.687 28.5792 30.4651 29.0435 29.9561C29.6146 29.3297 29.8342 28.8272 29.9723 27.8027C30.0412 27.274 29.9723 26.765 29.7212 26.0602C29.5267 25.5121 28.9431 24.9248 28.1839 24.5202C27.8765 24.3505 25.0652 23.078 21.9403 21.688C18.8154 20.298 16.2365 19.1365 16.2113 19.1103C16.1799 19.0842 17.14 18.5296 18.3448 17.8835C20.541 16.7023 26.0817 13.7396 27.7697 12.8391C29.4577 11.945 29.9095 11.2403 29.9095 9.48483C29.9095 8.51249 29.6836 7.84687 29.1 7.12251C28.5165 6.3851 27.7007 5.99355 26.7845 6.00008C26.4834 6.00008 26.0817 6.04576 25.8998 6.09797Z"
      fill="#3D55AE"
    />
    <path
      d="M5.93298 24.8146C5.41844 25.1017 5.06704 25.6629 5.01684 26.2894C4.96664 26.8115 5.07959 27.0855 5.49374 27.5163C5.87651 27.9209 5.50001 27.7381 10.1748 29.8328C10.9718 30.1853 11.6243 30.4854 11.6369 30.4985C11.6495 30.5051 10.7835 30.9814 9.72305 31.5426C5.60668 33.7287 5.46864 33.8202 5.16116 34.4596C4.87252 35.06 4.97292 35.5234 5.53138 36.189C6.02711 36.7763 6.6295 36.9525 7.25699 36.6915C7.72133 36.4957 15.7344 32.1691 16.0921 31.9146C16.4749 31.6535 16.8074 31.2555 16.9518 30.9031C17.0647 30.6225 17.071 29.9829 16.9643 29.7415C16.751 29.2586 16.2176 28.8409 15.2199 28.3711C14.0653 27.8294 8.34883 25.2518 7.66486 24.9646C6.84912 24.6252 6.3534 24.5796 5.93298 24.8146Z"
      fill="#FD9802"
    />
  </svg>
</template>
