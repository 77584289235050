<script setup>
const props = defineProps({
  color: {
    type: String,
    default: 'white'
  }
})
</script>
<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_521_68)">
      <path
        d="M6.86622 0C3.02167 0 0 2.80622 0 6.26422C0 7.99167 1.099 9.71911 2.28978 10.9076C2.198 12.096 0.640889 12.6389 0 13.3933C1.37278 13.3933 3.02167 12.8543 3.93789 11.9887C4.67056 12.2057 6.13433 12.5284 6.95956 12.5284C10.8049 12.5284 13.8266 9.71911 13.8266 6.26422C13.8266 2.80622 10.7139 0 6.86622 0Z"
        :fill="props.color"
      />
    </g>
    <defs>
      <clipPath id="clip0_521_68">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
