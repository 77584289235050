<script setup>
const props = defineProps({
  color: {
    type: String,
    default: 'white'
  }
})
</script>
<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_521_74)">
      <path
        d="M7.77778 0V4.66667H14V0H7.77778ZM7.77778 14H14V6.22222H7.77778V14ZM0 14H6.22222V9.33333H0V14ZM0 7.77778H6.22222V0H0V7.77778Z"
        :fill="props.color"
      />
    </g>
    <defs>
      <clipPath id="clip0_521_74">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
