<script setup>
const props = defineProps({
  color: {
    type: String,
    default: 'white'
  }
})
</script>
<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_521_70)">
      <path
        d="M6.86512 0C3.14627 0 0.170166 2.90909 0.170166 6.54545V11.6364C0.170166 12.8436 1.16712 13.8182 2.40206 13.8182H4.63322V8H1.65785V6.54545C1.65785 3.73091 3.98627 1.45455 6.86512 1.45455C9.74472 1.45455 12.0731 3.73091 12.0731 6.54545V8H9.09704V13.8182H12.0731V14.5455H6.86512V16H11.3289C12.5639 16 13.5608 15.0255 13.5608 13.8182V6.54545C13.5608 2.90909 10.5626 0 6.86512 0Z"
        :fill="props.color"
      />
    </g>
    <defs>
      <clipPath id="clip0_521_70">
        <rect
          width="14"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
