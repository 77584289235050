<script setup>
import { onMounted, onBeforeUnmount, ref, watch } from 'vue'
import { menuHelpers } from './menu.js'
import ChevronRight from '@/assets/icons/ChevronRight.vue'
import ChevronLeft from '@/assets/icons/ChevronLeft.vue'

const MAIN_MENU = 'MAIN_MENU'
const SUBMENU = 'SUBMENU'

const menu = ref({ list: [], pathActive: true })
const step = ref('')
const targetMenu = ref('')

const props = defineProps(['menuConfig', 'signOut'])
const emit = defineEmits(['mobile-menu-trigger'])

onMounted(() => {
  document.addEventListener('click', (e) => { menuHelpers.outClick(menu, e) })
  step.value = MAIN_MENU
})

onBeforeUnmount(() => { document.removeEventListener('click', (e) => menuHelpers.outClick(menu, e)) })

const methods = {

  onClickMenu: (e) => {
    step.value = SUBMENU
    targetMenu.value = e
  },

  onClickSubItem: (e) => {
    step.value = MAIN_MENU
    menuHelpers.subItemClick(e, menu)
    methods.toggleMenu()
  },

  returnToMenu: () => (step.value = MAIN_MENU),

  toggleMenu: () => (emit('mobile-menu-trigger', false))

}

watch(props.menuConfig, (newMenu) => { menu.value = newMenu })
</script>

<template>
  <ul class="navbar-list">
    <transition name="slide-fade-left">
      <li
        v-if="step === MAIN_MENU"
        key="main-menu"
      >
        <template
          v-for="(item, index) in menu.list"
          :key="index"
        >
          <div
            v-if="item.align === 'left'"
            class="navbar-item"
            @click="methods.onClickMenu(item.label)"
          >
            <component
              :is="item.icon"
              class="icon-menu-mobile"
              color="#6F6F6F"
            />
            <span>{{ item.label }}</span>
            <ChevronRight color="#6F6F6F" />
          </div>
        </template>
      </li>
    </transition>
    <transition name="slide-fade-left">
      <li
        v-if="step === SUBMENU"
        key="submenu"
        class="submenu-container-mobile"
      >
        <template
          v-for="(item, index) in menu.list"
          :key="index"
        >
          <div v-if="item.label === targetMenu">
            <div
              class="submenu-menu-button"
              @click="methods.returnToMenu"
            >
              <ChevronLeft
                color="#6F6F6F"
                style="margin-right: 5px"
              />
              <span>Menu</span>
            </div>
            <hr class="solid">
            <template
              v-for="(subitem, subindex) in item.menu"
              :key="subindex"
            >
              <div class="navbar-subitem">
                <template v-if="subitem.title">
                  <span class="nav-title">
                    {{ subitem.label }}
                  </span>
                </template>
                <template v-if="subitem.link">
                  <a
                    :href="subitem.link"
                    @click="methods.onClickSubItem"
                    :class="`submenu ${subitem.active ? 'active' : ''}`.trim()"
                  >{{ subitem.label }}</a>
                </template>
              </div>
            </template>
          </div>
        </template>
      </li>
    </transition>
  </ul>
</template>

<style lang="sass" scoped>
  hr.solid
    border-top: 1px solid #9F9F9F
    margin-bottom: 0
    margin-top: 0
    width: 80%

  .icon-menu-mobile
    height: 18px
    width: 18px

  .navbar-list
    list-style-type: none
    margin: 0
    padding: 0

  .navbar-item
    align-items: center
    cursor: pointer
    display: flex
    flex-direction: row
    height: 50px
    justify-content: space-between
    list-style-type: none
    padding: 0 10px 0 10px
    width: 100%
    &:hover
      background-color: rgb(216,216,216,0.5)
      padding-left: 20px
      transition: opacity 0.5s ease-out 0.5s, background-color 0.5s, color 0.5s, padding 0.3s

  .nav-title
    color: #9f9f9f
    font-size: 12px
    font-weight: 500
    padding-left: 15px
    width: 100%

  .nav-separator
    border-top: 1px solid #d9d9d9
    display: block
    margin: 8px 20px
    width: 100%

  .navbar-subitem
    align-items: center
    display: flex
    justify-content: left

  .submenu
    align-items: center
    color: #6f6f6f
    display: flex
    height: 50px
    justify-content: left
    list-style-type: none
    padding-left: 15px
    text-decoration: none
    width: 100%
    &:hover
      background-color: rgb(216,216,216,0.5)
      padding-left: 20px
      transition: opacity 0.5s ease-out 0.5s, background-color 0.5s, color 0.5s, padding 0.5s

  .submenu-menu-button
    align-items: center
    cursor: pointer
    display: flex
    height: 50px
    padding-left: 15px
    &:hover
      background-color: rgb(216,216,216,0.5)
      transition: opacity 0.5s ease-out 0.5s, background-color 0.5s, color 0.5s

  .submenu-container-mobile
    max-height: 90vh
    overflow: scroll
    position: absolute
    top: 0
    width: 200px

  .slide-fade-left-enter-active, .slide-fade-left-leave-active
    transition: transform 0.5s ease-out, opacity 0.5s ease-out

  .slide-fade-left-enter-from, .slide-fade-left-leave-to
    opacity: 0
    transform: translateX(-100%)

  .slide-fade-left-enter-to, .slide-fade-left-leave-from
    opacity: 1
    transform: translateX(0)
</style>
